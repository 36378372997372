import React from "react";

const Privacy = () => {
  return (
    <div className="w-full bg-white">
      <div className="max-w-7xl mx-auto px-10 md:px-20 py-20">
        <div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            This Privacy Policy outlines how Astra Intel LLC ("Astra Intel")
            collects, uses, and discloses information about you when you visit
            our website (astraintel.co) or use any of our associated online
            platforms, including mobile sites, applications, and other services
            that link to this Privacy Policy (collectively, the "Site").
          </p>
        </div>
        <div className="pt-10">
          <div className="text-black text-3xl title title pb-4 ">
            Changes to the Privacy Policy
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            We may update this Privacy Policy periodically. If changes are made,
            we will revise the date at the top of the policy. In certain
            instances, we may provide additional notification, such as an update
            on our homepage or through an email notice. We encourage you to
            review this Privacy Policy regularly to stay informed about our
            information practices and how you can help protect your privacy.
          </p>
          <div className="text-black text-3xl title title pt-4 pb-4 text-center">
            Information We Collect
          </div>
          <div className="text-black text-2xl title title pt-4 pb-4 ">
            Information We Collect
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            We collect information you voluntarily provide directly to us. This
            may include when you request information, fill out a form, send us
            an email, sign up for updates, or engage with us in other ways. The
            types of information we collect can include your name, email
            address, postal address, phone number, mobile number, payment
            information, and any other information you choose to share.
          </p>
          <div className="text-black text-2xl title title pt-4 pb-4 ">
            Information We Collect Automatically
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            When you access or use our Site, we may automatically gather
            information about you, including:
          </p>
          <li className=" text-primary text-lg leading-6 pb-2 title font-normal">
            <strong className="title  text-black text-xl ">
              Log Information:
            </strong>{" "}
            We log details about your use of the Site, such as your browser
            type, access times, pages viewed, IP address, and the page you
            visited before arriving at our Site.
          </li>
          <li className=" text-primary text-lg leading-6 pb-2 title font-normal">
            <strong className="title  text-black text-xl ">
              Device Information
            </strong>{" "}
            We collect information about the device you use to access our Site,
            including the hardware model, operating system and version, device
            identifiers, and mobile network information.
          </li>
          <li className=" text-primary text-lg leading-6 pb-4 title font-normal">
            <strong className="title  text-black text-xl  ">
              Cookies and Tracking Technologies:
            </strong>{" "}
            We use cookies and similar tracking technologies to collect
            information about your use of our Site. Cookies are small data files
            stored on your device that help us improve our Site and your
            experience. We may also use web beacons (or "tracking pixels"),
            which are electronic images used to track how you interact with our
            Site or emails. For more details on how to manage cookies, see "Your
            Choices" below.
          </li>
          <div className="text-black text-2xl title title pt-4 pb-4 ">
            Use of Information
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            We may use the information we collect for various purposes,
            including to:
          </p>
          <li className=" text-primary text-lg leading-6 pb-2 title font-normal">
            Operate, maintain, and improve our Site;
          </li>
          <li className=" text-primary text-lg leading-6 pb-2 title font-normal">
            Send you updates, newsletters, and other communications that may
            interest you;
          </li>
          <li className=" text-primary text-lg leading-6 pb-2 title font-normal">
            Respond to your inquiries, comments, and requests;
          </li>
          <li className=" text-primary text-lg leading-6 pb-2 title font-normal">
            Monitor and analyze usage, trends, and activities related to our
            Site;
          </li>
          <li className=" text-primary text-lg leading-6 pb-2 title font-normal">
            Combine information from other sources to enhance our services and
            communications with you; and
          </li>
          <li className=" text-primary text-lg leading-6 pb-4 title font-normal">
            Carry out any other purpose disclosed to you at the time of
            collection.
          </li>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            We may also share aggregated or anonymized information that does not
            directly identify you.
          </p>
          <div className="text-black text-2xl title title pt-4 pb-4 ">
            Data Retention
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            We retain personal information for as long as necessary to fulfill
            the purposes outlined in this Privacy Policy unless a longer
            retention period is required or permitted by law. We will securely
            delete or anonymize information when it is no longer needed.
          </p>
          <div className="text-black text-2xl title title pt-4 pb-4 ">
            Children’s Privacy
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            Our Site is not intended for use by children under the age of 13. We
            do not knowingly collect personal information from children under
            13. If we become aware that we have inadvertently received personal
            information from a user under the age of 13, we will delete such
            information from our records.
          </p>
          <div className="text-black text-2xl title title pt-4 pb-4 ">
            Social Sharing Features
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            Our Site may include social sharing features (e.g., the Facebook
            "Like" button), which allow you to share your activities on our Site
            with other media platforms. The use of these features may result in
            the collection or sharing of information, depending on the settings
            of the social sharing feature you use. For more information, refer
            to the privacy policies of the respective social media platforms.
          </p>
          <div className="text-black text-2xl title title pt-4 pb-4 ">
            Third-Party Links
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            Our Site may contain links to other websites or services that are
            not operated by Astra Intel. If you click on a third-party link, you
            will be directed to that third party's site. We are not responsible
            for the privacy practices or content of these external sites and
            encourage you to review their privacy policies before providing any
            information.
          </p>
          <div className="text-black text-2xl title title pt-4 pb-4 ">
            Analytics Services
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            We may allow third parties to collect information about your use of
            the Site through cookies, web beacons, or other tracking
            technologies. These analytics services help us understand how you
            use the Site, which content is popular, and how we can enhance your
            online experience.
          </p>
          <div className="text-black text-2xl title title pt-4 pb-4 ">
            Security
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            Astra Intel takes reasonable steps to protect your information from
            unauthorized access, use, disclosure, alteration, or destruction.
          </p>
          <div className="text-black text-2xl title title pt-4 pb-4 text-center">
            Your Choices
          </div>
          <div className="text-black text-2xl title title pt-4 pb-4 ">
            Cookies
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            You can usually configure your web browser to remove or reject
            cookies. However, this may affect the availability and functionality
            of the Site.
          </p>
          <div className="text-black text-2xl title title pt-4 pb-4 ">
            Updates and Communications
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            You can opt out of receiving newsletters and other communications by
            following the instructions provided in those communications. Even if
            you opt out, we may still send you transactional or
            relationship-related communications.
          </p>
          <div className="text-black text-2xl title title pt-4 pb-4 ">
            Contact Us
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            If you have any questions about this Privacy Policy, please contact
            us at info@astraintel.co.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
