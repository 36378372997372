import React from 'react'
import Layout from "../../Layout";
import Header from './header';
import Privacy from './privacy';

const Index = () => {
    return (
        <Layout>
            <Header/>
            <Privacy/>
        </Layout>
    )
}

export default Index
